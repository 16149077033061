/* src/pages/Home.css */

.home-container {
  text-align: center;
  padding: 50px 20px;
  background-color: #e6e6fa; /* Ligero fondo violeta */
}

.home-container h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #4b0082; /* Tonos en violeta */
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-details-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.2em;
  color: #fff;
  background-color: #4b0082; /* Tonos en violeta */
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.view-details-button:hover {
  background-color: #6a0dad; /* Tonos en violeta */
}
