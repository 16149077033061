/* src/components/WhatsAppButton.css */

.whatsapp-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    margin-top: 20px;
    font-size: 1.2em;
    color: #fff;
    background-color: #25d366; /* Color de WhatsApp */
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  
  .whatsapp-button:hover {
    background-color: #1ebe57; /* Un tono más oscuro para el hover */
  }
  
  .whatsapp-button svg {
    margin-right: 10px;
  }
  