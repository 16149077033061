/* src/components/PetDetail.css */

.pet-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 600px;
  margin: auto;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #4b0082; /* Tonos en violeta */
}

.pet-image img {
  width: 150px; /* Ajusta el tamaño del avatar según sea necesario */
  height: 150px; /* Ajusta el tamaño del avatar según sea necesario */
  border-radius: 50%; /* Hace que la imagen sea redonda */
  margin-bottom: 20px;
  object-fit: cover; /* Asegura que la imagen cubra el área del avatar */
  border: 2px solid #4b0082; /* Añade un borde violeta */
}

.pet-info {
  text-align: center;
}

.pet-info h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pet-info p {
  margin: 5px 0;
  font-size: 1.2em;
  color: #555;
}

.pet-info strong {
  color: #333;
}

.pet-info svg {
  margin-right: 10px;
  color: #4b0082; /* Tonos en violeta */
}
