/* src/index.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.home, .pet-detail {
  padding: 20px;
}

.pet-detail {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
}

.pet-detail h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

.pet-detail p {
  margin: 5px 0;
}

a {
  text-decoration: none;
  color: blue;
}
